import React, { useState, useEffect } from "react";
import { Card, CardContent } from "../../components/shadcn/Card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../../components/shadcn/Carousel";
import { Badge } from "../../components/catalyst/badge";
import {
  ArrowTopRightOnSquareIcon,
  XMarkIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMeta } from "@fortawesome/free-brands-svg-icons";
import { extractRealUrl } from "./util";

const AdCard = ({ ad, adCreatives }) => {
  const BASE_AD_LIBRARY_URL = "https://www.facebook.com/ads/library/?id=";
  const [showFullText, setShowFullText] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMedia, setModalMedia] = useState(null);
  const maxTextLength = 200; // The maximum length before truncation

  // Handle Escape key to close modal
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    // Cleanup the event listener
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isModalOpen]);

  const handleToggleText = () => {
    setShowFullText((prev) => !prev);
  };

  const handleOpenModal = (media) => {
    setModalMedia(media);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalMedia(null);
  };

  const renderStatusBadge = () => {
    const statusText =
      ad.status === "Active"
        ? `Active Since ${new Date(ad.startDate).toLocaleDateString()}`
        : `Inactive Since ${new Date(ad.end_date).toLocaleDateString()}`;
    const badgeColor = ad.status === "Active" ? "green" : "red";

    return <Badge color={badgeColor}>{statusText}</Badge>;
  };

  const renderPlatformBadges = (platform) => {
    switch (platform) {
      case "Facebook":
        return (
          <Badge key={platform} color="blue" className="mr-2">
            {platform}
          </Badge>
        );
      case "Instagram":
        return (
          <Badge key={platform} color="purple" className="mr-2">
            {platform}
          </Badge>
        );
      case "Messenger":
        return (
          <Badge key={platform} color="sky" className="mr-2">
            {platform}
          </Badge>
        );
      case "Audience Network":
        return (
          <Badge key={platform} color="red" className="mr-2">
            {platform}
          </Badge>
        );
      default:
        return <></>;
    }
  };

  const renderAdContent = () => {
    const titleClasses = "text-lg font-semibold inline";
    const buttonClasses = "text-blue-500 hover:text-blue-700 ml-2 text-sm";
    const text = ad.adContent?.text || "";
    const isTruncated = text.length > maxTextLength;

    return (
      <>
        <div className={titleClasses}>
          {isTruncated && !showFullText
            ? text.slice(0, maxTextLength) + "..."
            : text}
        </div>
        {isTruncated && (
          <button onClick={handleToggleText} className={buttonClasses}>
            {showFullText ? "See less" : "See more"}
          </button>
        )}
      </>
    );
  };

  const renderAdImages = () => {
    if (!adCreatives || adCreatives.length === 0) {
      return null;
    }

    return (
      <div className="flex justify-center items-center mb-4 w-full">
        {adCreatives.length === 1 ? (
          // Single Creative
          <div className="relative w-3/4 md:w-1/2">
            {ad.media_type === "single_image" ? (
              <img
                src={adCreatives[0].url}
                alt={adCreatives[0].file_name}
                className="w-full h-auto object-cover rounded shadow-md"
              />
            ) : ad.media_type === "video" ? (
              <video
                controls
                src={adCreatives[0].url}
                className="w-full h-auto object-cover rounded shadow-md"
              />
            ) : null}
            <button
              onClick={() => handleOpenModal(adCreatives[0])}
              className="absolute top-2 right-2 p-2 rounded-full bg-white bg-opacity-80 hover:bg-opacity-100 shadow-md"
            >
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-800" />
            </button>
          </div>
        ) : (
          // Multiple Creatives (Carousel)
          <Carousel className="w-full">
            <CarouselContent>
              {adCreatives.map((creative, index) => (
                <CarouselItem key={index}>
                  <div className="p-1 relative flex justify-center">
                    <Card>
                      <CardContent className="flex items-center justify-center p-6 relative">
                        <div className="relative w-3/4 md:w-1/2">
                          {creative.mime_type === "video/mp4" ? (
                            <video
                              controls
                              src={creative.url}
                              className="w-full h-auto object-cover rounded shadow-md"
                            />
                          ) : (
                            <img
                              src={creative.url}
                              alt={creative.file_name}
                              className="w-full h-auto object-cover rounded shadow-md"
                            />
                          )}
                          <button
                            onClick={() => handleOpenModal(creative)}
                            className="absolute top-2 right-2 p-2 rounded-full bg-white bg-opacity-80 hover:bg-opacity-100 shadow-md"
                          >
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-800" />
                          </button>
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="absolute left-2 top-1/2 transform -translate-y-1/2" />
            <CarouselNext className="absolute right-2 top-1/2 transform -translate-y-1/2" />
          </Carousel>
        )}
      </div>
    );
  };

  const sectionClasses =
    "self-stretch flex-col justify-start items-start flex gap-1";
  const fieldClasses = "text-gray-500 font-bold text-sm";
  const valueClasses = "text";

  return (
    <Card className="relative">
      <div className="absolute top-2 right-2 flex space-x-3">
        <a
          href={BASE_AD_LIBRARY_URL + ad.libraryId}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faMeta}
            className="h-5 w-5 text-blue-500 hover:text-blue-700"
            title="View in Ad Library"
          />
        </a>
        {ad.adContent?.linkPreview?.url && (
          <a
            href={extractRealUrl(ad.adContent?.linkPreview?.url)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ArrowTopRightOnSquareIcon
              className="h-5 w-5 text-blue-500 hover:text-blue-700"
              title="Visit Product Page"
            />
          </a>
        )}
      </div>

      <CardContent>
        <div className="flex-col justify-start items-start gap-3 inline-flex mt-4">
          <div className="text-sm text-gray-500">
            <a
              href={ad.advertiser?.profileUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {ad.advertiser?.name}
            </a>
          </div>
          {renderStatusBadge()}

          <div>{renderAdImages()}</div>
          <div>{renderAdContent()}</div>

          <div className="justify-start items-start gap-sm inline-flex flex-wrap gap-y-2">
            {ad.platforms?.map((platform) => renderPlatformBadges(platform))}
          </div>

          {ad.adContent?.linkPreview?.tagline && (
            <div className={sectionClasses}>
              <div className={fieldClasses}>Tagline</div>
              <div className={valueClasses}>
                {ad.adContent.linkPreview.tagline}
              </div>
            </div>
          )}

          {ad.adContent?.linkPreview?.cta && (
            <div className={sectionClasses}>
              <div className={fieldClasses}>Call to Action</div>
              <div className={valueClasses}>
                {ad.adContent.linkPreview.cta}
              </div>
            </div>
          )}
        </div>
      </CardContent>

      {/* Modal Implementation */}
      {isModalOpen && modalMedia && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75"
          onClick={handleCloseModal}
        >
          <div
            className="relative bg-white rounded-lg shadow-lg max-w-full max-h-full overflow-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>
            <div className="p-4 flex justify-center items-center">
              {modalMedia.mime_type === "video/mp4" ? (
                <video
                  controls
                  src={modalMedia.url}
                  className="max-w-full max-h-screen object-contain rounded"
                />
              ) : (
                <img
                  src={modalMedia.url}
                  alt={modalMedia.file_name}
                  className="max-w-full max-h-screen object-contain rounded"
                />
              )}
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};

export default AdCard;
