import axios from 'axios';

const sendCompanyRequest = async ({companyName, url}) => {
    const baseURL = 'https://newengen-backend.instalily.ai';
    if (!companyName) {
        console.error('No feedback provided');
        return;
    }
    if (!url) {
        console.error('No feedback provided');
        return;
    }

    try {
        console.log('Sending Company Request:', companyName, url);
        const response = await axios.post(`${baseURL}/request-new-company`, {
            companyName: String(companyName),
            url: String(url),
        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        
        console.log('Conmpany rerquest sent successfully:', response.data);
    } catch (err) {
        console.error('Error sending company request:', err);
    }
};

export default sendCompanyRequest;