import PieChartAnalysis from "../../components/analytics/PieChartAnalysis";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../../components/shadcn/Card";
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import { Subheading } from '../../components/catalyst/heading';
import { useAppContext } from '../../contexts/AppContext';
import { extractRealUrl } from "./util";
import { ChartContainer, ChartLegend, ChartLegendContent } from "../../components/shadcn/Chart";

// Helper functions 
function capitalizeFirstLetterOfEachWord(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

// Generate a random color sequence based on the base color palette
const generateRandomColor = () => {
  // Adjust the saturation and lightness to create pastel-like colors
  const randomHue = Math.random() * 360; // Full hue range
  const randomSaturation = 50 + Math.floor(Math.random() * 30); // Random saturation between 50% and 80%
  const randomLightness = 60 + Math.floor(Math.random() * 20); // Random lightness between 60% and 80%
  const colorWithVariation = `hsl(${randomHue}, ${randomSaturation}%, ${randomLightness}%)`;
  return colorWithVariation;
};

const generateAdFormatChartData = (currentAds) => {
  const adFormatCounts = currentAds.reduce((counts, ad) => {
    const mediaType = ad.media_type;
    if (!counts[mediaType]) {
      counts[mediaType] = 0;
    }
    counts[mediaType]++;
    return counts;
  }, {});
  const chartData = [
    { label: "Image", value: adFormatCounts["single_image"], fill: "#8fa6f0" },   // Darkened from #b3c1f5
    { label: "Video", value: adFormatCounts["video"], fill: "#e792a6" },          // Darkened from #f5b3c9
    { label: "Carousel", value: adFormatCounts["carousel"], fill: "#e7b592" },    // Darkened from #f5d4b3
  ];
  return chartData;
};

const generateAdPlatformChartData = (currentAds) => {
  const platformCounts = currentAds.reduce((counts, ad) => {
    ad.platforms.forEach((platform) => {
      const platformLowerCase = platform.toLowerCase();
      counts[platformLowerCase] = (counts[platformLowerCase] || 0) + 1;
    });
    return counts;
  }, {});

  const chartData = [
    { label: "Facebook", value: platformCounts["facebook"], fill: "#7DA0FF" },   // Darkened from #b3c1f5
    { label: "Instagram", value: platformCounts["instagram"], fill: "#C480FF" }, // Darkened from #d5b7f7
    { label: "Messenger", value: platformCounts["messenger"], fill: "#69B3DD" }, // Darkened from #b3d9f4
    { label: "Audience Network", value: platformCounts["audience network"], fill: "#F57F7F" }, // Darkened from #f5b3b3
  ];
  return chartData;
};

// Color palette for CTA Pie Chart, maps cta -> color, dynamically generated 
let ctaColors = {};
const generateAdCTAChartData = (currentAds) => {
  // Count the number of each CTA
  let styledCTA;
  const ctaCounts = currentAds.reduce((counts, ad) => {
    const cta = ad?.adContent?.linkPreview?.cta;
    if (!cta) return counts;
    styledCTA = capitalizeFirstLetterOfEachWord(cta);
    counts[styledCTA] = (counts[styledCTA] || 0) + 1;
    return counts;
  }, {});

  // Map the CTA counts to chart data with corresponding colors
  const chartData = Object.entries(ctaCounts).map(([cta, value]) => {
    // Generate a random color or use an existing color for each CTA
    const fill = ctaColors[cta] || generateRandomColor();
    ctaColors[cta] = fill; // Store the color for this CTA for future use

    return { label: cta, value, fill };
  });

  return chartData;
}

const generateAdSitePlacementChartData = (currentAds) => {
  let homePageCount = 0;
  let productPageCount = 0;
  let collectionPageCount = 0;
  let customLPCount = 0;

  currentAds.forEach((ad) => {
    const realUrl = extractRealUrl(ad.adContent?.linkPreview?.url);
    if (realUrl) {
      const parsedRealUrl = new URL(realUrl);

      // Check for Home Page
      if (parsedRealUrl.pathname === '/' && !parsedRealUrl.search && !parsedRealUrl.hash) {
        homePageCount++;
      } else if (parsedRealUrl.pathname.includes('/product')) {
        // Check for Product Page
        productPageCount++;
      } else if (parsedRealUrl.pathname.includes('/collection') || parsedRealUrl.pathname.includes('/category') || parsedRealUrl.pathname.includes('/shop/')) {
        // Check for Collection Page
        collectionPageCount++;
      } else if (parsedRealUrl.pathname.includes('/pages/') || parsedRealUrl.pathname.includes('/lp') || parsedRealUrl.pathname.includes('/promo')) {
        // Check for Custom Landing Page
        customLPCount++;
      } else {
        // Fallback as Product Page (or you can add other logic)
        productPageCount++;
      }
    }
  });

  const chartData = [
    { label: "Home Page", value: homePageCount, fill: "#e76b6b" },
    { label: "Product Page", value: productPageCount, fill: "#7075e0" },
    { label: "Collection Page", value: collectionPageCount, fill: "#6be78e" }, // Darkened from #00FF00
    { label: "Custom LP", value: customLPCount, fill: "#e7d36b" }, // Darkened from #FFFF00
  ];

  return chartData;
};


////////////////////////////////////////
// Ad Card calculation functions 
////////////////////////////////////////
const calculateAverageAdPostFrequencyPerMonth = (allAds) => {
  if (allAds.length === 0) return 0;

  // Group ads by the start of their month
  const adsByMonth = allAds.reduce((months, ad) => {
    const adStartDate = new Date(ad.startDate);

    // Find the month the ad was posted (start of the month)
    const monthStart = startOfMonth(adStartDate);
    const monthStartStr = monthStart.toISOString().slice(0, 7); // Use the month (YYYY-MM) as a key

    if (!months[monthStartStr]) {
      months[monthStartStr] = 0;
    }

    months[monthStartStr] += 1; // Increment the count for the ads in this month
    return months;
  }, {});

  // Calculate the total number of months in the dataset
  const allMonthStarts = Object.keys(adsByMonth);
  const totalMonths = allMonthStarts.length;

  // Calculate the average ad post frequency per month
  const totalAds = allAds.length;
  const averageAdPostFrequencyPerMonth = totalAds / totalMonths;

  return averageAdPostFrequencyPerMonth;
};

const calculateAverageAdPostFrequencyPerWeek = (allAds) => {
  if (allAds.length === 0) return 0;

  // Group ads by the start of their week (Monday)
  const adsByWeek = allAds.reduce((weeks, ad) => {
    const adStartDate = new Date(ad.startDate);
    // Find the Monday of the week the ad was posted
    const weekStart = startOfWeek(adStartDate, { weekStartsOn: 1 }); // Monday as the start of the week
    const weekStartStr = weekStart.toISOString().slice(0, 10); // Use the week start date as a key
    if (!weeks[weekStartStr]) {
      weeks[weekStartStr] = 0;
    }
    weeks[weekStartStr] += 1; // Increment the count for the ads in this week
    return weeks;
  }, {});

  // Calculate the total number of weeks in the dataset
  const allWeekStarts = Object.keys(adsByWeek);
  const totalWeeks = allWeekStarts.length;
  // Calculate the average ad post frequency per week
  const totalAds = allAds.length;
  const averageAdPostFrequencyPerWeek = totalAds / totalWeeks;

  return averageAdPostFrequencyPerWeek;
};

// Main component
const Analytic = ({ companyId, currentAds, allAds }) => {
  const { allCompetitors } = useAppContext();
  if (!currentAds || !allAds) {
    return null;
  }
  const title = allCompetitors.find(company => company.getId() === companyId)?.getName();
  const numCurrentAds = currentAds.length;

  const adsLast7Days = allAds.filter((ad) => {
    const adStartDate = new Date(ad.startDate).getTime();
    // Get today's date and calculate the date 7 days ago
    const today = new Date().getTime();
    const sevenDaysAgo = new Date(today - 7 * 24 * 60 * 60 * 1000).getTime(); // 7 days in milliseconds
    // Filter ads that started in the past 7 days
    return adStartDate >= sevenDaysAgo && adStartDate <= today;
  }).length;

  const adsLast30Days = allAds.filter((ad) => {
    const adStartDate = new Date(ad.startDate).getTime();
    // Get today's date and calculate the date 30 days ago
    const today = new Date().getTime();
    const thirtyDaysAgo = new Date(today - 30 * 24 * 60 * 60 * 1000).getTime(); // 30 days in milliseconds
    // Filter ads that started in the past 30 days
    return adStartDate >= thirtyDaysAgo && adStartDate <= today;
  }).length;

  const platformChartData = generateAdPlatformChartData(currentAds);
  const adFormatChartData = generateAdFormatChartData(currentAds);
  const adCTAData = generateAdCTAChartData(currentAds);
  const averageAdPostFrequencyPerWeek = calculateAverageAdPostFrequencyPerWeek(allAds);
  const averageAdPostFrequencyPerMonth = calculateAverageAdPostFrequencyPerMonth(allAds);
  const adSitePlacementChartData = generateAdSitePlacementChartData(currentAds);
  // TODO Average ad duration calculation
  return (
    <div className="bg-gray-50 p-4 rounded-2xl mb-4">
      <h2 level={2} className="mb-4 text-xl">{title}</h2>
      <div className="flex">
        {/* Left Column: Cards */}
        <div className="flex flex-col space-y-4 flex-4">
          <Card className="text-center">
            <CardHeader>
              <CardTitle tag="h5">{numCurrentAds}</CardTitle>
              <CardDescription>Active Ads</CardDescription>
            </CardHeader>
          </Card>

          <Card className="text-center">
            <CardHeader>
              <CardTitle tag="h5">{adsLast7Days}</CardTitle>
              <CardDescription>Ads Started Last 7 Days</CardDescription>
            </CardHeader>
          </Card>

          <Card className="text-center">
            <CardHeader>
              <CardTitle tag="h5">{adsLast30Days}</CardTitle>
              <CardDescription>Ads Started Last 30 Days</CardDescription>
            </CardHeader>
          </Card>
        </div>

        {/* Right Column: Pie Charts */}
        <div className="flex flex-col flex-grow ml-8 h-full">
          <div className="flex space-x-3">
            {/* <div className="w-1/4">
              <PieChartAnalysis
                chartData={platformChartData}
                chartDescription="Platform for Active Ads"
              />
            </div> */}
            <div className="w-1/3">
              <PieChartAnalysis
                chartData={adFormatChartData}
                chartDescription="Format for Active Ads"
              />
            </div>
            <div className="w-1/3">
              <PieChartAnalysis
                chartData={adCTAData}
                chartDescription="CTA for Active Ads"
              />
            </div>
            <div className="w-1/3">
              <PieChartAnalysis
                chartData={adSitePlacementChartData}
                chartDescription="Site Placement for Active Ads"
              />
            </div>
          </div>

          {/* Average Analytics */}
          {/* Wrap the cards inside a single flex container */}
          <div className="flex flex-wrap space-x-3 mt-8">
            <Card className="text-center flex-1 w-1/3">
              <CardHeader>
                <CardTitle tag="h5">{averageAdPostFrequencyPerWeek.toFixed(2)}</CardTitle>
                <CardDescription>Average Ad Post Frequency Per Week</CardDescription>
              </CardHeader>
            </Card>

            <Card className="text-center flex-1 w-1/3">
              <CardHeader>
                <CardTitle tag="h5">{averageAdPostFrequencyPerMonth.toFixed(2)}</CardTitle>
                <CardDescription>Average Ad Post Frequency Per Month</CardDescription>
              </CardHeader>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytic;
