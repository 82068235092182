import axios from 'axios';
import { Company } from '../cls/Company';

const getClients = async (baseURL, accessToken) => {
  const apiUrl = `${baseURL}/get-clients`;

  try {
    const response = await axios.get(apiUrl, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    let companies = []
    for (let client of response.data) {
      companies.push(new Company(client.id, client.company_name, client.last_updated));
    }
    return companies;

  } catch (error) {
    if (error.response) {
      console.error('Error response data:', error.response.data); // More detailed error from the server
      console.error('Error response status:', error.response.status); 
    } else {
      console.error('Error fetching clients:', error.message);
    }
    return null;  // Return null or handle appropriately
  }
};

export default getClients;
