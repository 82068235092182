import React, { useEffect, useState } from "react";
import { Subheading } from "../../components/catalyst/heading";
import { startOfMonth, startOfWeek, endOfMonth, endOfWeek } from 'date-fns';
import { useAppContext } from "../../contexts/AppContext";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
  CardContent,
} from "../../components/shadcn/Card";

const ComparativeInsights = ({ selectedCompetitors, currentAdsDict, allAdsDict }) => {
  const { client } = useAppContext();
  const [topCompaniesByActiveAds, setTopCompaniesByActiveAds] = useState([]);
  const [topCompaniesByAdsStartedLast7Days, setTopCompaniesByAdsStartedLast7Days] = useState({});
  const [topCompaniesByAdsStartedLast30Days, setTopCompaniesByAdsStartedLast30Days] = useState({});
  const [topCompaniesByImageAds, setTopCompaniesByImageAds] = useState({});
  const [topCompaniesByVideoAds, setTopCompaniesByVideoAds] = useState({});
  const [topCompaniesByCarouselAds, setTopCompaniesByCarouselAds] = useState({});

  // Calculate metrics for top companies by active ads, ads started this week, ads started this month, image ads, and video ads
  useEffect(() => {
    if (selectedCompetitors.length > 0 && currentAdsDict) {
      const topCompaniesAllTime = calculateTopCompaniesByActiveAds(selectedCompetitors, currentAdsDict);
      setTopCompaniesByActiveAds(topCompaniesAllTime);

      const topCompaniesAdsStartedLast7Days = calculateTopCompaniesByAdsStartedLast7Days(selectedCompetitors, allAdsDict);
      setTopCompaniesByAdsStartedLast7Days(topCompaniesAdsStartedLast7Days);

      const topCompaniesAdsStartedLast30Days = calculateTopCompaniesByAdsStartedLast30Days(selectedCompetitors, allAdsDict);
      setTopCompaniesByAdsStartedLast30Days(topCompaniesAdsStartedLast30Days);

      const topCompaniesImages = calculateTopCompaniesByImageAds(selectedCompetitors, currentAdsDict);
      setTopCompaniesByImageAds(topCompaniesImages);

      const topCompaniesVideos = calculateTopCompaniesByVideoAds(selectedCompetitors, currentAdsDict);
      setTopCompaniesByVideoAds(topCompaniesVideos);

      const topCompaniesCarousel = calculateTopCompaniesByCarouselAds(selectedCompetitors, currentAdsDict);
      setTopCompaniesByCarouselAds(topCompaniesCarousel);
    }
  }, [selectedCompetitors, currentAdsDict]);

  // Function to calculate the top companies by active ads across all time
  const calculateTopCompaniesByActiveAds = (selectedCompetitors, currentAdsDict) => {
    const topActiveAds = {};
    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor.getId(); // Assuming getId() returns the unique identifier for the competitor
      const numCurrentAds = currentAdsDict[competitorId]?.length || 0;
      topActiveAds[competitor.getName()] = numCurrentAds;
    });
    return topActiveAds;
  };

  const calculateTopCompaniesByAdsStartedLast7Days = (selectedCompetitors, allAdsDict) => {
    const topAdsStartedLast7Days = {};

    // Get the current date
    const today = new Date().getTime();

    // Calculate the date 7 days ago
    const sevenDaysAgo = new Date(today - 7 * 24 * 60 * 60 * 1000).getTime(); // 7 days in milliseconds

    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor.getId(); // Assuming getId() returns the unique identifier for the competitor
      const competitorAds = allAdsDict[competitorId] || [];

      let last7DaysCount = 0;

      // Iterate through each ad for the competitor
      competitorAds.forEach(ad => {
        const adStartDate = new Date(ad.startDate).getTime();

        // Check if the ad is active and started within the last 7 days
        if (adStartDate >= sevenDaysAgo && adStartDate <= today) {
          last7DaysCount++;
        }
      });

      topAdsStartedLast7Days[competitor.getName()] = last7DaysCount;
    });

    return topAdsStartedLast7Days;
  };

  const calculateTopCompaniesByAdsStartedLast30Days = (selectedCompetitors, allAdsDict) => {
    const topAdsStartedLast30Days = {};

    // Get the current date
    const today = new Date().getTime();

    // Calculate the date 30 days ago
    const thirtyDaysAgo = new Date(today - 30 * 24 * 60 * 60 * 1000).getTime(); // 30 days in milliseconds

    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor.getId(); // Assuming getId() returns the unique identifier for the competitor
      const competitorAds = allAdsDict[competitorId] || [];

      let last30DaysCount = 0;

      // Iterate through each ad for the competitor
      competitorAds.forEach(ad => {
        const adStartDate = new Date(ad.startDate).getTime();

        // Check if the ad is active and started within the last 30 days
        if (adStartDate >= thirtyDaysAgo && adStartDate <= today) {
          last30DaysCount++;
        }
      });

      topAdsStartedLast30Days[competitor.getName()] = last30DaysCount;
    });

    return topAdsStartedLast30Days;
  };

  // Function to calculate the top companies by live image ads
  const calculateTopCompaniesByImageAds = (selectedCompetitors, currentAdsDict) => {
    const topImageAds = {};

    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor.getId();
      const competitorAds = currentAdsDict[competitorId] || [];

      let imageAdsCount = 0;

      // Iterate through each ad for the competitor
      competitorAds.forEach(ad => {
        const isActive = ad.status === "Active";
        const isImage = ad.media_type === "single_image";

        // Check if the ad is active and is an image ad
        if (isActive && isImage) {
          imageAdsCount++;
        }
      });

      topImageAds[competitor.getName()] = imageAdsCount;
    });

    return topImageAds;
  };

  // Function to calculate the top companies by live video ads
  const calculateTopCompaniesByVideoAds = (selectedCompetitors, currentAdsDict) => {
    const topVideoAds = {};

    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor.getId();
      const competitorAds = currentAdsDict[competitorId] || [];

      let videoAdsCount = 0;

      // Iterate through each ad for the competitor
      competitorAds.forEach(ad => {
        const isActive = ad.status === "Active";
        const isVideo = ad.media_type === "video";

        // Check if the ad is active and is a video ad
        if (isActive && isVideo) {
          videoAdsCount++;
        }
      });

      topVideoAds[competitor.getName()] = videoAdsCount;
    });

    return topVideoAds;
  };

  const sortCompaniesByAds = (companies) => {
    return Object.entries(companies).sort((a, b) => b[1] - a[1]);
  };


  function getRainbowColor(totalItems, index) {
    const ratio = index / totalItems;
    const hue = Math.floor((1 - ratio) * 100); // From 100 (green) to 0 (red)

    // Adjust saturation and lightness for pastel effect
    const saturation = 70;  // Lower saturation for softer colors
    const lightness = 60;   // Increase lightness for a pastel look

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  }

  // Function to calculate the top companies by live carousel ads
  const calculateTopCompaniesByCarouselAds = (selectedCompetitors, currentAdsDict) => {
    const topCarouselAds = {};

    selectedCompetitors.forEach(competitor => {
      const competitorId = competitor.getId();
      const competitorAds = currentAdsDict[competitorId] || [];

      let carouselAdsCount = 0;

      // Iterate through each ad for the competitor
      competitorAds.forEach(ad => {
        const isActive = ad.status === "Active";
        const isCarousel = ad.media_type === "carousel";

        // Check if the ad is active and is a video ad
        if (isActive && isCarousel) {
          carouselAdsCount++;
        }
      });

      topCarouselAds[competitor.getName()] = carouselAdsCount;
    });

    return topCarouselAds;
  };

  return (
    <div className="mb-6 grid grid-cols-6 gap-3">
      {[
        { title: "Active Ads", data: topCompaniesByActiveAds },
        { title: "Ads Started Last 7 Days", data: topCompaniesByAdsStartedLast7Days },
        { title: "Ads Started Last 30 Days", data: topCompaniesByAdsStartedLast30Days },
        { title: "Image Ads Live", data: topCompaniesByImageAds },
        { title: "Video Ads Live", data: topCompaniesByVideoAds },
        { title: "Carousel Ads Live", data: topCompaniesByCarouselAds },
      ].map(({ title, data }, columnIndex) => (
        <Card className="text-center">
          <div className='p-2'>
            <h3
              className="text-l pt-2 pb-4 font-semibold leading-none tracking-tight"
            >
              {title}
            </h3>
            <CardDescription>
              <ol className="list-decimal list-inside w-full">
                {sortCompaniesByAds(data).map(([company, count], rowIndex) => (
                  <li
                    key={rowIndex}
                    className="p-1 rounded text-black text-xs mb-1"
                    style={{
                      backgroundColor: "#fbfbfb",
                      border: "1px solid #bbb"
                    }}
                  >
                    {company === client?.name ? (
                      <strong>{company} - {count}</strong>
                    ) : (
                      `${company} - ${count}`
                    )}
                  </li>
                ))}
              </ol>
            </CardDescription></div>
        </Card>
      ))
      }
    </div >
  )
}

export default ComparativeInsights;

