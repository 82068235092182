import { Textarea } from '../../components/catalyst/textarea'
import { Button } from '../../components/catalyst/button'
import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../components/catalyst/dialog'
import { Field, Label } from '../../components/catalyst/fieldset'
import { Input } from '../../components/catalyst/input'
import { useState } from 'react'
import sendFeedback from '../../api/sendFeedback'
import { useAppContext } from '../../contexts/AppContext'

const Feedback = () => {
  const { baseURL } = useAppContext()
  let [isOpen, setIsOpen] = useState(false)
  let [feedback, setFeedback] = useState('')

  const onClickSend = async () => {
    await sendFeedback(baseURL, feedback)
    setFeedback('')
    setIsOpen(false)
    alert('Feedback sent!')
  }

  return (
    <>
      <Button type="button" onClick={() => setIsOpen(true)}>
        Give Feedback
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>Give Feedback</DialogTitle>
        <DialogDescription>
          Give us your feedback on what we can improve or any additional feature requests you have!
        </DialogDescription>
        <DialogBody>
          <Field>
            <Label>Feedback</Label>
            <Textarea name="feedback" value={feedback} onChange={(e) => setFeedback(e.target.value)}/>
          </Field>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button onClick={onClickSend}>Send</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default Feedback;