import { useEffect, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import DateRangeSelector, { defaultDateRange } from '../../components/filter/DateRangeSelector.js';
import CompanySelector from "../offers/CompanySelector";
import { Heading } from "../../components/catalyst/heading";
import { Divider } from "../../components/catalyst/divider";
import AdCards from "./AdCards";
import Analytics from "./Analytics.js";
import { Radio, RadioGroup } from 'rsuite';
import { Checkbox, CheckboxGroup } from 'rsuite';
import { mergeAdsDict, useFetchAllCompetitorsAds, useFetchCurrentCompetitorsAds, countPlatforms } from "./util.js";
import { Field, Label } from '../../components/catalyst/fieldset';
import ComparativeInsights from "./ComparativeInsights.js";

const AdsPage = () => {
  const { selectedCompetitors } = useAppContext();
  const ACTIVE_FILTER_LIST = [
    { name: 'All Ads', value: 'all' },
    { name: 'Active Ads', value: 'active' },
    { name: 'Inactive Ads', value: 'inactive' },
  ];
  const SORT_LIST = [
    { name: 'Most Recent', value: 'recent' },
    { name: 'Least Recent', value: 'least' },
  ];
  const [PLATFORMS_FILTER_LIST, setPLATFORMS_FILTER_LIST] = useState([
    { displayname: 'All', value: 'all' },
    { displayname: 'Facebook', value: 'facebook' },
    { displayname: 'Instagram', value: 'instagram' },
    { displayname: 'Messenger', value: 'messenger' },
    { displayname: 'Audience Network', value: 'audience_network' },
  ]);

  const [activeFiltersSelected, setActiveFiltersSelected] = useState(ACTIVE_FILTER_LIST.map(filter => filter.value));
  const [sortSelected, setSortSelected] = useState(SORT_LIST[0].value);
  const [platformsSelected, setPlatformsSelected] = useState(PLATFORMS_FILTER_LIST.map(platform => platform.value));
  const [dateRange, setDateRange] = useState(defaultDateRange);

  const { currentAdsDict, currentAdsLoading, currentAdsError } = useFetchCurrentCompetitorsAds(selectedCompetitors);
  const { allAdsDict, adsLoading, adsError } = useFetchAllCompetitorsAds(selectedCompetitors);

  const [currentAds, setCurrentAds] = useState([]);
  const [allAds, setAllAds] = useState([]);

  // Reset currentAds when no competitors are selected
  useEffect(() => {
    if (selectedCompetitors.length === 0) {
      setCurrentAds([]);
    } else if (currentAdsDict && Object.keys(currentAdsDict).length > 0) {
      console.log("Current Ads Dict:", currentAdsDict);
      setCurrentAds(mergeAdsDict(currentAdsDict));
    }
  }, [selectedCompetitors, currentAdsDict]);

  useEffect(() => {
    if (selectedCompetitors.length === 0) {
      setAllAds([]);
    } else if (allAdsDict && Object.keys(allAdsDict).length > 0) {
      console.log("All Ads Dict:", allAdsDict);
      setAllAds(mergeAdsDict(allAdsDict));
    }
  }, [selectedCompetitors, allAdsDict]);

  // Update platform filters based on current ads and date range
  useEffect(() => {
    if (currentAds.length > 0) {
      const filteredAds = currentAds.filter(ad => {
        const adStartDate = new Date(ad.startDate).setHours(0, 0, 0, 0);
        const startRange = new Date(dateRange[0]).setHours(0, 0, 0, 0);
        const endRange = new Date(dateRange[1]).setHours(0, 0, 0, 0);
        return adStartDate >= startRange && adStartDate <= endRange;
      });
      const counts = countPlatforms(filteredAds);

      const updatedPlatformsList = PLATFORMS_FILTER_LIST.map(platform => ({
        ...platform,
        name: platform.value === 'all'
          ? `All (${filteredAds.length})`
          : `${platform.displayname} (${counts[platform.value] || 0})`
      }));

      setPLATFORMS_FILTER_LIST(updatedPlatformsList);
    } else {
      // Reset platform filters when no ads are present
      setPLATFORMS_FILTER_LIST(PLATFORMS_FILTER_LIST.map(platform => ({
        ...platform,
        name: platform.displayname
      })));
    }
  }, [currentAds, dateRange]);

  // Handle filter changes (unchanged from your code)
  const handleFilterChange = (checkedValues, currentSelected, setSelected, filterList) => {
    // If all was previously selected, and one other filter is now deselected, also deselect all
    if (currentSelected.includes('all') && checkedValues.includes('all') && checkedValues.length < currentSelected.length) {
      setSelected(checkedValues.filter(value => value !== 'all'));
    }
    // if all is now deselected, deselect all
    else if (currentSelected.includes('all') && !checkedValues.includes('all')) {
      setSelected([]);
    }
    // if length - 1 of checkedValues is now selected, select all
    // or all is now selected, select all
    else if (checkedValues.length === filterList.length - 1
      || (checkedValues.includes('all') && !currentSelected.includes('all'))
    ) {
      setSelected(filterList.map(filter => filter.value));
    } else {
      setSelected(checkedValues);
    }
  };

  const handleActiveFilterChange = (checkedValues) => {
    handleFilterChange(checkedValues, activeFiltersSelected, setActiveFiltersSelected, ACTIVE_FILTER_LIST);
  };

  const handlePlatformChange = (checkedValues) => {
    handleFilterChange(checkedValues, platformsSelected, setPlatformsSelected, PLATFORMS_FILTER_LIST);
  };

  return (
    <div className="w-full p-6 border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <div className="mb-6">
        <Heading>Paid Ads Tracker</Heading>
      </div>
      <div className="mb-6">
        <CompanySelector />
      </div>
      {selectedCompetitors.length === 0 && (
        <p><strong>Select a competitor to view their ads.</strong></p>
      )}
      {selectedCompetitors.length > 0 && (
        <ComparativeInsights
          selectedCompetitors={selectedCompetitors}
          currentAdsDict={currentAdsDict}
          allAdsDict={allAdsDict}
        />
      )}
      {selectedCompetitors.length > 0 && currentAdsLoading && (
        <p className="text-sm text-zinc-600">Loading all ads...</p>
      )}
      {currentAdsError && <p>Error: {currentAdsError}</p>}
      {selectedCompetitors.length > 0 && !currentAdsLoading && !currentAdsError && currentAds.length > 0 && (
        <>
          <Analytics
            currentAdsDict={currentAdsDict}
            allAdsDict={allAdsDict}
          />
          <Divider className="my-6" />
          <Heading className="mb-4">All Ads</Heading>
          <div className="flex flex-col gap-2 mb-4">
            {/* Filters */}
            <div className="grid grid-cols-2 gap-8">
              <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
              <Field>
                <Label>Sort By</Label>
                <RadioGroup name="radio-group-inline" inline value={sortSelected} onChange={setSortSelected}>
                  {SORT_LIST.map((sort, index) => (
                    <Radio key={index} value={sort.value}>{sort.name}</Radio>
                  ))}
                </RadioGroup>
              </Field>
            </div>

            <Field>
              <Label>Filter By</Label>
              <CheckboxGroup
                inline
                name="active-filter-group"
                value={activeFiltersSelected}
                onChange={handleActiveFilterChange}
              >
                {ACTIVE_FILTER_LIST.map((filter, index) => (
                  <Checkbox key={index} value={filter.value}>{filter.name}</Checkbox>
                ))}
              </CheckboxGroup>
            </Field>
            <Field>
              <Label>Platforms</Label>
              <CheckboxGroup
                inline
                name="platform-filter-group"
                value={platformsSelected}
                onChange={handlePlatformChange}
              >
                {PLATFORMS_FILTER_LIST.map((platform, index) => (
                  <Checkbox key={index} value={platform.value}>{platform.name}</Checkbox>
                ))}
              </CheckboxGroup>
            </Field>
          </div>

          <div className="flex flex-col min-h-screen">
            <AdCards
              ads={allAds}
              dateRange={dateRange}
              activeFilterSelected={activeFiltersSelected}
              sortFilterSelected={sortSelected}
              platformFilterSelected={platformsSelected}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default AdsPage;
