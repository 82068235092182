import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AuthGuard = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  // If Auth0 is still determining the authentication status, return null to not render anything yet.
  if (isLoading) return null;

  // If the user isn't authenticated after the loading is done, don't render children.
  if (!isAuthenticated) return null;

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;