import axios from 'axios';

const maxRetries = 0;

const getAllAds = async (baseURL, accessToken, companyId, retryCount = 0) => {
  if (!companyId) {
    throw new Error('No company ID provided');
  }

  try {
    const response = await axios.get(`${baseURL}/query-all-ads`, {
      params: { company_id: companyId },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.data && Object.keys(response.data).length > 0) {
      return response.data;
    } else if (retryCount < maxRetries) {
      console.warn(`Retrying fetch: attempt ${retryCount + 1}`);
      return await getAllAds(companyId, retryCount + 1); // Retry the fetch
    } else {
      console.log(`No ads returned after ${maxRetries} attempts for companyId: ${companyId}`);
      return [];
    }
  } catch (err) {
    if (retryCount < maxRetries) {
      console.warn(`Retrying fetch: attempt ${retryCount + 1}`);
      return await getAllAds(companyId, retryCount + 1); // Retry the fetch on error
    } else {
      console.error('Error loading all ads:', err);
      throw err; // Re-throw the error after retries are exhausted
    }
  }
};

export default getAllAds;
