import React, { useEffect, useState, useRef, useMemo } from "react";
import AdCard from "./AdCard";

import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/shadcn/Pagination";
import getAdCreativesByLibraryIds from "../../api/getAdCreativesByLibraryIds";
import { useAppContext } from "../../contexts/AppContext";

const AdCards = ({ ads: allAds, dateRange, activeFilterSelected, sortFilterSelected, platformFilterSelected }) => {
  const { baseURL, accessToken } = useAppContext();
  
  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [currentAds, setCurrentAds] = useState([]);
  const [adCreatives, setAdCreatives] = useState({});
  const adsPerPage = 40;

  // To store the previous libraryIds
  const prevLibraryIdsRef = useRef([]);

  // Memoize filtered ads
  const filteredAds = useMemo(() => {
    return allAds?.filter(ad => {
      const adStartDate = new Date(ad.startDate).setHours(0, 0, 0, 0);
      const startRange = new Date(dateRange[0]).setHours(0, 0, 0, 0);
      const endRange = new Date(dateRange[1]).setHours(0, 0, 0, 0);
      return adStartDate >= startRange && adStartDate <= endRange;
    }).filter(ad => {
      if (activeFilterSelected.includes('all')) return true;
      if (activeFilterSelected.includes('active')) return ad.status === 'Active';
      if (activeFilterSelected.includes('inactive')) return ad.status === 'inactive';
      return false;
    }).filter(ad => {
      if (platformFilterSelected.includes('all')) return true;
      return platformFilterSelected.some(platform => {
        switch (platform) {
          case 'facebook': return ad.platforms.includes('Facebook');
          case 'instagram': return ad.platforms.includes('Instagram');
          case 'messenger': return ad.platforms.includes('Messenger');
          case 'audience_network': return ad.platforms.includes('Audience Network');
          default: return false;
        }
      });
    }).sort((a, b) => {
      if (sortFilterSelected === 'recent') return new Date(b.startDate) - new Date(a.startDate);
      if (sortFilterSelected === 'least') return new Date(a.startDate) - new Date(b.startDate);
      return 0;
    });
  }, [allAds, dateRange, activeFilterSelected, sortFilterSelected, platformFilterSelected]);

  // Effect for setting the current ads based on the page
  useEffect(() => {
    const startIndex = (currentPage - 1) * adsPerPage;
    const endIndex = currentPage * adsPerPage;
    setCurrentAds(filteredAds.slice(startIndex, endIndex));
  }, [currentPage, filteredAds]);

  // Effect for fetching ad creatives
  useEffect(() => {
    const fetchAdCreatives = async () => {
      const libraryIds = currentAds.map((ad) => ({ library_id: ad.libraryId, company_id: ad.company_id }));
      
      // Check if libraryIds have changed
      const libraryIdsChanged = !arraysEqual(prevLibraryIdsRef.current, libraryIds);
      if (libraryIdsChanged) {
        try {
          const response = await getAdCreativesByLibraryIds(baseURL, accessToken, libraryIds);
          setAdCreatives(response);
          // Update the previous libraryIds ref
          prevLibraryIdsRef.current = libraryIds;
        } catch (error) {
          console.error("Error fetching ad creatives:", error);
        }
      }
    };

    if (currentAds.length > 0) fetchAdCreatives();
  }, [baseURL, currentAds]);

  // Function to deeply compare two arrays
  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i].library_id !== arr2[i].library_id || arr1[i].company_id !== arr2[i].company_id) {
        return false;
      }
    }
    return true;
  };

  const totalPages = Math.ceil(filteredAds.length / adsPerPage);
  
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {/* Content */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 gap-4" >
        {currentAds?.map((ad) => (
          <AdCard key={ad.libraryId} ad={ad} adCreatives={adCreatives?.[ad.libraryId] ?? []} />
        ))}
      </div>
  
      {/* Pagination Component */}
      <div className="flex justify-center mt-4 mb-4">
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious href="#" onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)} />
            </PaginationItem>
            {Array.from({ length: totalPages }, (_, i) => (
              <PaginationItem key={i}>
                <PaginationLink 
                  href="#"
                  isActive={currentPage === i + 1}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            {totalPages > 5 && <PaginationEllipsis />}
            <PaginationItem>
              <PaginationNext href="#" onClick={() => handlePageChange(currentPage < totalPages ? currentPage + 1 : totalPages)} />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </>
  );
};

export default AdCards;
