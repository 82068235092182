import { useAppContext } from '../../contexts/AppContext';
import { useEffect, useState } from 'react';
import { Field, Label } from '../../components/catalyst/fieldset';
import { TagPicker } from 'rsuite';
import 'rsuite/dist/rsuite-no-reset.min.css';

const CompanySelector = () => {
  const { allCompetitors, setSelectedCompetitors, selectedCompetitors } = useAppContext();
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);

  // Synchronize the selected competitors from the app context on mount and when they change
  useEffect(() => {
    setData(allCompetitors?.map(item => ({ label: item?.getName(), value: item?.getId() })));
    setValue(selectedCompetitors?.map(company => company?.getId()));
  }, [allCompetitors, selectedCompetitors]);

  const updateCurrentCompany = (companyIds) => {
    const companies = companyIds.map(id => allCompetitors.find(c => c.getId() === id));
    setSelectedCompetitors(companies); // Update selected competitors in context
    setValue(companyIds); // Update the value for TagPicker
  };

  return (
    <Field>
      <Label><strong>Competitors</strong></Label>
      <TagPicker 
        data={data} 
        onChange={updateCurrentCompany} 
        value={value}  // Reflect current value from state
        block 
      />
    </Field>
  );
};

export default CompanySelector;
