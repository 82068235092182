import { useAppContext } from '../../contexts/AppContext';
const { DropdownMenu, DropdownItem, DropdownLabel, DropdownDivider, DropdownButton } = require('../catalyst/dropdown');

const ClientDropdownMenu = () => {
  const { clients, setClient } = useAppContext();

  const handleClientChange = (client) => {
    return () => {
      setClient(client);
    }
  }

  return (
    <DropdownMenu className="min-w-80 lg:min-w-64" anchor="bottom start">
      {/* <DropdownItem onClick={() => navigate('/teams/1/settings')}>
          <Cog8ToothIcon />
          <DropdownLabel>Settings</DropdownLabel>
      </DropdownItem> */}
      {/* <DropdownDivider /> */}
      {clients?.map((client) => (
        <DropdownItem key={client.id} onClick={handleClientChange(client)}>
          {client.name}
        </DropdownItem>
      ))}
      {/* <DropdownDivider /> */}
    </DropdownMenu>
  );
}

export default ClientDropdownMenu;