import Analytic from "./Analytic";

// Main component
const Analytics = ({ currentAdsDict, allAdsDict }) => {
  // Check if currentAdsDict is valid
  if (!currentAdsDict || Object.keys(currentAdsDict).length === 0) return null;
  return (
    <>
      {Object.keys(currentAdsDict).map((key) => (
        <Analytic
          key={key}
          companyId={key}
          currentAds={currentAdsDict[key] || []}
          allAds={allAdsDict[key] || []}
        />
      ))}
    </>
  );
};

export default Analytics;
