import React from 'react';
import { Heading } from '../../components/catalyst/heading'

const HomePage = () => {
  return (
    <div className="flex w-full flex-wrap items-end justify-between gap-4 border-b border-zinc-950/10 pb-6 dark:border-white/10">
      <Heading>Home Page</Heading>
    </div>
  );
};
    
export default HomePage;