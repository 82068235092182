import { useNavigate } from 'react-router-dom';

import { Avatar } from '../catalyst/avatar';
import { Sidebar, SidebarBody, SidebarHeader, SidebarItem, SidebarLabel, SidebarSection } from '../catalyst/sidebar';
import {
    ChevronDownIcon,
} from '@heroicons/react/16/solid';
import {
    Dropdown,
    DropdownButton,
} from '../catalyst/dropdown';
import ClientDropdownMenu from './ClientDropDownMenu';
import { navItems } from './navitems';
import Feedback from './Feedback';
import LogoutButton from './Logout';
import RequestNewCompany from './RequestCompany';

const SiteSidebar = () => {
    const navigate = useNavigate();
    return (
        <Sidebar>
            <SidebarHeader>
            <Dropdown>
                <DropdownButton as={SidebarItem} className="lg:mb-2.5">
                <Avatar src="/tailwind-logo.svg" />
                <SidebarLabel>Tailwind Labs</SidebarLabel>
                <ChevronDownIcon />
                </DropdownButton>
                <ClientDropdownMenu />
            </Dropdown>
            </SidebarHeader>
            <SidebarBody>
            <SidebarSection>
                {navItems.map(({ label, url }) => (
                <SidebarItem key={label} onClick={() => navigate(url)}>
                    {label}
                </SidebarItem>
                ))}
            </SidebarSection>
            </SidebarBody>
        </Sidebar>
    )
}

export default SiteSidebar;