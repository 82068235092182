import { useState } from 'react';
import { Field, Label } from '../catalyst/fieldset'
import { DateRangePicker } from 'rsuite';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
const { afterToday } = DateRangePicker;

export const dateRanges = [
  { name: 'All time', value: 0 },
  { name: 'Last 30 days', value: new Date(new Date().setDate(new Date().getDate() - 30)).getTime() },
  { name: 'Last 7 days', value: new Date(new Date().setDate(new Date().getDate() - 7)).getTime() },
  { name: 'Last 3 days', value: new Date(new Date().setDate(new Date().getDate() - 3)).getTime() },
  { name: 'Last 2 days', value: new Date(new Date().setDate(new Date().getDate() - 2)).getTime() },
];

const placement = 'left';

const dateRangesOptions = [
  {
    label: 'Last 3 days',
    value: [subDays(new Date(), 2), new Date()],
    placement
  },
  {
    label: 'Last 7 days',
    value: [subDays(new Date(), 6), new Date()],
    placement
  },
  {
    label: 'Last 30 days',
    value: [subDays(new Date(), 29), new Date()],
    placement
  },
  {
    label: 'Last 3 months',
    value: [subMonths(new Date(), 3), new Date()],
    placement
  },
  {
    label: 'Last 6 months',
    value: [subMonths(new Date(), 6), new Date()],
    placement
  },
];

const defaultDateRange = dateRangesOptions[dateRangesOptions.length - 1].value;

export { defaultDateRange };


const DateRangeSelector = ({ dateRange, setDateRange }) => {
  const [dateRangeLabel, setDateRangeLabel] = useState(defaultDateRange.label);
  const updateDateRange = (range) => {
    // check if the range is valid
    if (!Array.isArray(range) || range.length !== 2 || !(range[0] instanceof Date) || !(range[1] instanceof Date)) {
      return defaultDateRange;
    }

    // find the label of the range
    const matchingRange = dateRangesOptions.find(defaultRange =>
      defaultRange.value[0].getTime() === range[0].getTime() &&
      defaultRange.value[1].getTime() === range[1].getTime()
    );
    const label = matchingRange ? matchingRange.label : 'Custom Range';
    setDateRangeLabel(label);
    
    console.log({ label, range })
    setDateRange(range);
  }

  return (
    <Field className="flex flex-col">
      <Label>Date Range</Label>
      <DateRangePicker format="MM/dd/yyyy" character=" - "
        ranges={dateRangesOptions}
        label={dateRangeLabel}
        value={dateRange}
        shouldDisableDate={afterToday()}
        onChange={updateDateRange} />
    </Field>
  )
}

export default DateRangeSelector;

