import { useState, useEffect, useRef } from 'react';
import { useAppContext } from '../../contexts/AppContext';
import getCurrentAds from "../../api/getCurrentAds";
import getAllAds from "../../api/getAllAds";
import getAdCreatives from '../../api/getAdCreatives';

export const useFetchCurrentCompetitorsAds = (competitors) => {
  const { baseURL, accessToken } = useAppContext();
  const [currentAds, setCurrentAds] = useState({});
  const [currentAdsLoading, setCurrentAdsLoading] = useState(false);
  const [currentAdsError, setCurrentAdsError] = useState(null);

  // Ref to track initial mount
  const isInitialMount = useRef(true);
  const previousCompetitors = useRef(competitors);

  useEffect(() => {
    const fetchAds = async () => {
      if (!competitors || competitors.length === 0) return;

      setCurrentAdsLoading(true);
      setCurrentAdsError(null);

      const allAds = {};
      try {
        for (const competitor of competitors) {
          const fetchedAds = await getCurrentAds(baseURL, accessToken, competitor?.getId());
          if (fetchedAds.length > 0) {
            allAds[competitor.getId()] = fetchedAds;
          }
        }
        setCurrentAds(allAds);
      } catch (err) {
        setCurrentAdsError(err.message);
      } finally {
        setCurrentAdsLoading(false);
      }
    };
    // Run effect only if competitors have changed or on initial mount
    if (!accessToken) {
      return
    }

    if (isInitialMount.current || !arraysEqual(previousCompetitors.current, competitors)) {
      isInitialMount.current = false;
      previousCompetitors.current = competitors;
      fetchAds();
    }
  }, [competitors, accessToken]);

  return { currentAdsDict: currentAds, currentAdsLoading, currentAdsError };
};

export const useFetchAllCompetitorsAds = (competitors) => {
  const { baseURL, accessToken } = useAppContext();
  const [allAds, setAllAds] = useState({});
  const [adsLoading, setAdsLoading] = useState(false);
  const [adsError, setAdsError] = useState(null);

  // Ref to track initial mount
  const isInitialMount = useRef(true);
  const previousCompetitors = useRef(competitors);

  useEffect(() => {
    const fetchAds = async () => {
      if (!competitors || competitors.length === 0) return;

      setAdsLoading(true);
      setAdsError(null);

      const fetchedAds = {};
      try {
        for (const competitor of competitors) {
          const fetchedCompetitorAds = await getAllAds(baseURL, accessToken, competitor?.getId());
          if (fetchedCompetitorAds.length > 0) {
            fetchedAds[competitor.getId()] = fetchedCompetitorAds;
          }
        }
        setAllAds(fetchedAds);
      } catch (err) {
        setAdsError(err.message);
      } finally {
        setAdsLoading(false);
      }
    };
    if (!accessToken) {
      return
    }
    // Run effect only if competitors have changed or on initial mount
    if (isInitialMount.current || !arraysEqual(previousCompetitors.current, competitors)) {
      isInitialMount.current = false;
      previousCompetitors.current = competitors;
      fetchAds();
    }
  }, [competitors, accessToken]);

  return { allAdsDict: allAds, adsLoading, adsError };
};

// returns map libraryId -> adCreative[]
export const useGetAdCreatives = (competitors) => {
  const { baseURL, accessToken } = useAppContext();
  const [allCreatives, setAllCreatives] = useState({});
  const [creativesLoading, setCreativesLoading] = useState(false);
  const [creativesError, setCreativesError] = useState(null);

  // Ref to track initial mount
  const isInitialMount = useRef(true);
  const previousCompetitors = useRef(competitors);

  useEffect(() => {
    const fetchCreatives = async () => {
      if (!competitors || competitors.length === 0 || accessToken) return;

      setCreativesLoading(true);
      setCreativesError(null);

      const fetchedCreatives = {};
      try {
        for (const competitor of competitors) {
          const competitorId = competitor?.getId();
          const fetchedCompetitorCreatives = await getAdCreatives(baseURL, accessToken, competitorId);
            
          // Merge all entries from fetchedCompetitorCreatives into fetchedCreatives
          for (const [libraryId, creatives] of Object.entries(fetchedCompetitorCreatives)) {
            if (!fetchedCreatives[libraryId]) {
              fetchedCreatives[libraryId] = creatives;
            } else {
              fetchedCreatives[libraryId] = [
                ...fetchedCreatives[libraryId],
                ...creatives,
              ];
            }
          }            
        }
        setAllCreatives(fetchedCreatives);
      } catch (err) {
        setCreativesError(err.message);
      } finally {
        setCreativesLoading(false);
      }
    };
    if (isInitialMount.current || !arraysEqual(previousCompetitors.current, competitors)) {
      isInitialMount.current = false;
      previousCompetitors.current = competitors;
      fetchCreatives();
    }
  }, [competitors, accessToken]);

  return { allCreativesDict: allCreatives, creativesLoading, creativesError };
};

export const mergeAdsDict = (ads) => {
  const mergedAds = [];
  for (const competitorId in ads) {
    mergedAds.push(...ads[competitorId]);
  }
  return mergedAds;
}

export const countPlatforms = (ads) => {
  return ads.reduce((counts, ad) => {
    ad.platforms.forEach(platform => {
      const platformKey = platform.toLowerCase().replace(' ', '_');
      counts[platformKey] = (counts[platformKey] || 0) + 1;
    });
    return counts;
  }, {});
};

export const extractRealUrl = (redirectUrl) => {
  // Parse the redirect URL
  if (!redirectUrl) return null;

  const parsedUrl = new URL(redirectUrl);

  // Extract the query parameters
  const queryParams = new URLSearchParams(parsedUrl.search);

  // Find the original URL
  if (queryParams.has('u')) {
    const originalUrl = queryParams.get('u');
    try {
      const realUrl = decodeURIComponent(originalUrl);
      return realUrl;
    }
    catch (err) {
      console.error('Error decoding URL:', err);
      return null;
    }
  } else {
    return null;
  }
}

// Helper function to check equality between two arrays
const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].getId() !== arr2[i].getId()) {
      return false;
    }
  }
  return true;
};