import axios from 'axios';

const getAdCreativesByLibraryIds = async (baseURL, accessToken, libraryIds) => {
  const apiUrl = `${baseURL}/ad-creatives-by-library-ids`;

  // Ensure the payload matches the expected format
  const payload = {
    library_ids: libraryIds,
  };


  try {
    const response = await axios.post(apiUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    console.log('Ad Creatives:', response.data);
    return response.data;

  } catch (error) {
    if (error.response) {
      console.error('Error response data:', error.response.data); // More detailed error from the server
      console.error('Error response status:', error.response.status); 
    } else {
      console.error('Error fetching ad creatives:', error.message);
    }
    return null;  // Return null or handle appropriately
  }
};

export default getAdCreativesByLibraryIds;
