"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Company = void 0;
class Company {
    constructor(id, name, timestamp, websiteurls) {
        this.id = id;
        this.name = name;
        this.timestamp = timestamp;
        this.websiteurls = websiteurls;
    }
    getId() {
        return this.id;
    }
    getName() {
        return this.name;
    }
    getTimestamp() {
        return this.timestamp;
    }
    getWebsiteurls() {
        return this.websiteurls;
    }
    static fromJson(json) {
        if (json === null) {
            return null;
        }
        return new Company(json.id, json.name, new Date(json.timestamp), json.websiteurls);
    }
}
exports.Company = Company;
