import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import {
  Dropdown,
  DropdownButton,
} from '../catalyst/dropdown';
import { Navbar, NavbarDivider, NavbarItem, NavbarLabel, NavbarSection, NavbarSpacer } from '../catalyst/navbar';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import ClientDropdownMenu from './ClientDropDownMenu';
import { navItems } from './navitems';
import Feedback from './Feedback';
import RequestNewCompany from './RequestCompany';
import LogoutButton from './Logout';

function SiteNavbar() {
  const navigate = useNavigate();
  const { client } = useAppContext();

  return (
    <Navbar>
      <Dropdown>
        <DropdownButton as={NavbarItem} className="max-lg:hidden">
          <NavbarLabel>{client?.name || "Select Brand"}</NavbarLabel>
          <ChevronDownIcon />
        </DropdownButton>
        <ClientDropdownMenu />
      </Dropdown>
      <NavbarDivider className="max-lg:hidden" />
      <NavbarSection className="max-lg:hidden">
        {navItems.map(({ label, url }) => (
          <NavbarItem key={label} onClick={() => navigate(url)}>
            {label}
          </NavbarItem>
        ))}
      </NavbarSection>
      <NavbarSpacer />
      <NavbarSection>
        <NavbarItem aria-label="Feedback">
          <Feedback></Feedback>
        </NavbarItem>
        <NavbarItem aria-label="Request Company">
          <RequestNewCompany></RequestNewCompany>
        </NavbarItem>
        <NavbarItem aria-label="Logout">
          <LogoutButton
            className="text-red-600 hover:text-red-800"
          />
        </NavbarItem>

        {/* <NavbarItem aria-label="Notifications">
          <BellIcon />
        </NavbarItem> */}
        {/* <Dropdown>
          <DropdownButton as={NavbarItem}>
            <Avatar src="/profile-photo.jpg" square />
          </DropdownButton>
          <DropdownMenu className="min-w-64" anchor="bottom end">
            <DropdownItem onClick={() => navigate('/my-profile')}>
              <UserIcon />
              <DropdownLabel>My profile</DropdownLabel>
            </DropdownItem>
            <DropdownItem onClick={() => navigate('/settings')}>
              <Cog8ToothIcon />
              <DropdownLabel>Settings</DropdownLabel>
            </DropdownItem>
            <DropdownDivider />
            <DropdownItem onClick={() => navigate('/privacy-policy')}>
              <ShieldCheckIcon />
              <DropdownLabel>Privacy policy</DropdownLabel>
            </DropdownItem>
            <DropdownItem onClick={() => navigate('/share-feedback')}>
              <LightBulbIcon />
              <DropdownLabel>Share feedback</DropdownLabel>
            </DropdownItem>
            <DropdownDivider />
            <DropdownItem onClick={() => navigate('/logout')}>
              <ArrowRightStartOnRectangleIcon />
              <DropdownLabel>Sign out</DropdownLabel>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown> */}
      </NavbarSection>
    </Navbar>
  )
}

export default SiteNavbar;
